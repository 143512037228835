import React from 'react';

export interface MyProps {
  activeStep: number;
}
const StepsModule = (props: React.PropsWithChildren<MyProps>) => {
  const { activeStep } = props;
  const steps = [
    {
      step: 'Step 1',
      description: 'Select NFT'
    },
    {
      step: 'Step 2',
      description: 'Select Terms'
    },
    {
      step: 'Step 3',
      description: 'Confirm'
    }
  ];
  return (
    <>
      {activeStep ? (
        <div className='row col-lg-8 col-md-9 col-12 margin-auto steps-container'>
          {steps.map((step, index: number) => (
            <div
              key={step.step}
              className={index == activeStep - 1 ? 'step orange-text' : 'step'}
            >
              <span className='step-number'>{step.step}</span>
              <span className='step-desc'>{step.description}</span>
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default StepsModule;

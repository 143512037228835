import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import axios from 'axios';
import { baseURL } from 'config';
import { NFTmessage } from 'pages/types/NFTMessage';
import {
  BoostInfo,
  ComapnyLoanIssuedInfo,
  NFTCollection,
  NFTCollectionAdmin,
  NFTCompany,
  PawnFeeInfoAdmin,
  PawnHistory,
  PawnNFT,
  PawnShopStats,
  TierLoanInfo
} from 'pages/types/PawnLoanType';
import PawnShopSmartContract from './pawn-shop-smart-contract';
export default class CommonHelper {
  public static async loadCollectionDetails(
    proxy: ProxyNetworkProvider,
    collectionName: string
  ) {
    const collectionInfoFromSC =
      await PawnShopSmartContract.getNFTCollectionByTokenIdentifier(
        collectionName,
        proxy
      );
    if (collectionInfoFromSC == null) {
      throw new Error('Could not load NFT collections');
    }
    const tiersLoanInfo = await PawnShopSmartContract.getAllTiersLoanInfo(
      proxy
    );
    if (tiersLoanInfo == null) {
      throw new Error('Could not load tier loan info');
    }

    let identifier = `${collectionName}-02`;
    if (collectionName.toUpperCase() === 'VPRSFT-832941') {
      identifier = `${collectionName}-01`;
    } else if (collectionName.toUpperCase() === 'SUBJECTX-2C184D') {
      identifier = `${collectionName}-216e`;
    }

    const collectionNFTsUrl = `${baseURL}nfts?identifiers=${identifier}`;
    const collectionNFTs = await axios.get(collectionNFTsUrl);
    if (collectionNFTs == null || undefined) {
      throw Error('nfts info could not found');
    }
    const nftType = collectionNFTs.data[0].url.split('.').pop();
    const imageurl =
      nftType.toLowerCase() != 'gif' && nftType.toLowerCase() != 'mp4'
        ? collectionNFTs.data[0].media[0].thumbnailUrl
        : collectionNFTs.data[0].url;
    for (let index = 0; index < tiersLoanInfo.length; index++) {
      const tierLoanInfo = tiersLoanInfo[index];
      if (
        collectionInfoFromSC.collection_tier.name.toString() ==
        tierLoanInfo.collection_tier.name.toString()
      ) {
        const collectionInfoLocal: NFTCollection = {
          collectionTokenIdentifier:
            collectionInfoFromSC.collection_token_identifier,
          collectionName: collectionInfoFromSC.collection_name.toString(),
          floorPrice: collectionInfoFromSC.floor_price.toNumber() / 10 ** 18,
          active: collectionInfoFromSC.active,
          companyName: collectionInfoFromSC.company_name.toString(),
          collectionTier: collectionInfoFromSC.collection_tier.name.toString(),
          collection_max_loan_percentage:
            tierLoanInfo.collection_max_loan_rate_percentage.toNumber(),
          url: imageurl
        };
        // console.log(collectionInfoLocal);
        // if (collectionInfoLocal !== null || undefined) {
        //   setNFTCollectionInfo(collectionInfoLocal);
        // }
        return collectionInfoLocal;
      }
    }
  }

  public static convertToHex(str: string) {
    let hex = '';
    // console.log(str);
    for (let i = 0; i < str.length; i++) {
      hex += '' + str.charCodeAt(i).toString(16);
    }
    return hex;
  }

  public static async loadSystemStats(proxy: ProxyNetworkProvider) {
    const statsFromSC = await PawnShopSmartContract.getPawnShopStats(proxy);
    // console.log(statsFromSC);
    const stats: PawnShopStats = {
      totalPoolAmount: statsFromSC.total_pool_amount.toNumber(),
      totalPoolAmountDelta: statsFromSC.total_pool_amount_delta.toNumber(),
      totalBorrowedAmount: statsFromSC.total_borrowed_amount.toNumber(),
      totalBorrowedAmountDelta:
        statsFromSC.total_borrowed_amount_delta.toNumber(),
      currentBorrowedAmount: statsFromSC.current_borrowed_amount.toNumber(),
      currentBorrowedAmountDelta:
        statsFromSC.current_borrowed_amount_delta.toNumber(),
      totalLoanRepaid: statsFromSC.total_loan_repaid.toNumber(),
      totalLoanRepaidDelta: statsFromSC.total_loan_repaid_delta.toNumber(),
      totalFeeCollected: statsFromSC.total_fee_collected.toNumber(),
      totalFeeCollectedDelta: statsFromSC.total_fee_collected_delta.toNumber(),
      totalDefaultedFee: statsFromSC.total_defaulted_fee.toNumber(),
      totalDefaultedFeeDelta: statsFromSC.total_defaulted_fee_delta.toNumber(),
      recoveredDefaultedLoan: statsFromSC.recovered_defaulted_loan.toNumber(),
      recoveredDefaultedFee: statsFromSC.recovered_defaulted_fee.toNumber(),
      totalActiveLoans: statsFromSC.total_active_loans.toNumber(),
      totalNumberOfGoodLoans: statsFromSC.total_number_of_good_loans.toNumber(),
      totalNumberOfBadLoans: statsFromSC.total_number_of_bad_loans.toNumber(),
      totalUsedStakedPasses:
        statsFromSC.total_number_of_staked_passes_in_use.toNumber(),
      totalAmountWithdrawn: statsFromSC.total_withdrawn_amount.toNumber(),
      totalDefaultedLoan: 0,
      totalLoanToRecover: 0,
      totalEGLDProfit: 0,
      userAddedPoolAmount: 0
    };

    stats.totalDefaultedLoan =
      Math.trunc(stats.totalBorrowedAmount / 10 ** 12) -
      (Math.trunc(stats.totalLoanRepaid / 10 ** 12) +
        Math.trunc(stats.currentBorrowedAmount / 10 ** 12));
    stats.totalLoanToRecover =
      stats.totalDefaultedLoan -
      Math.trunc(stats.recoveredDefaultedLoan / 10 ** 12);
    stats.totalEGLDProfit =
      Math.trunc(stats.totalFeeCollected / 10 ** 12) +
      Math.trunc(stats.recoveredDefaultedFee / 10 ** 12) -
      stats.totalLoanToRecover;
    stats.userAddedPoolAmount =
      Math.trunc(stats.totalPoolAmount / 10 ** 12) +
      Math.trunc(stats.totalAmountWithdrawn / 10 ** 12) -
      (Math.trunc(stats.totalFeeCollected / 10 ** 12) +
        Math.trunc(stats.recoveredDefaultedFee / 10 ** 12));

    return stats;
  }

  public static async loadPawnedNFTsForUser(
    proxy: ProxyNetworkProvider,
    address: string
  ) {
    const pawnedNFTsInfoFromSC =
      await PawnShopSmartContract.getAllPawnedNFTsForUser(proxy, address);
    return await CommonHelper.createPawnNFTsObject(pawnedNFTsInfoFromSC);
  }

  public static async getTransactionHistory(
    proxy: ProxyNetworkProvider,
    address: string
  ) {
    const expiredNFTsInfoFromSC =
      await PawnShopSmartContract.getPawnHistoryForAddress(proxy, address);
    const pawnedNFTsLocal: PawnHistory[] = [];
    for (let index = 0; index < expiredNFTsInfoFromSC.length; index++) {
      const pawnedNFTInfoFromSC = expiredNFTsInfoFromSC[index];
      const pawnNFT: PawnHistory = {
        tokenIdentifier:
          pawnedNFTInfoFromSC.token_info.token_identifier.toString(),
        nonce: pawnedNFTInfoFromSC.token_info.token_nonce.toNumber(),
        loanAmount: pawnedNFTInfoFromSC.loan_amount.toNumber(),
        pawnDate: pawnedNFTInfoFromSC.pawn_date.toNumber(),
        actualExpiryDate: pawnedNFTInfoFromSC.actual_expiry_date.toNumber(),
        extendedExpiryDate: pawnedNFTInfoFromSC.extended_expiry_date.toNumber(),
        loanDurationTicks: pawnedNFTInfoFromSC.loan_duration_ticks.toNumber(),
        pawnFee: pawnedNFTInfoFromSC.pawn_fee.toNumber(),
        nftCounter: pawnedNFTInfoFromSC.nft_counter.toNumber(),
        currentState: pawnedNFTInfoFromSC.current_state.name,
        nftCommonName: '',
        url: '',
        tags: []
      };

      pawnedNFTsLocal.push(pawnNFT);
    }

    return await CommonHelper.loadNftData(pawnedNFTsLocal);
  }

  public static async getAllExpiredLoanNFTs(proxy: ProxyNetworkProvider) {
    const expiredNFTsInfoFromSC =
      await PawnShopSmartContract.getAllExpiredLoansInfo(proxy);

    return await CommonHelper.createPawnNFTsObject(expiredNFTsInfoFromSC);
  }

  static async createPawnNFTsObject(pawnedNFTsFromSC: any[]) {
    const pawnedNFTsLocal: PawnNFT[] = [];
    for (let index = 0; index < pawnedNFTsFromSC.length; index++) {
      const pawnedNFTInfoFromSC = pawnedNFTsFromSC[index];
      const pawnNFT: PawnNFT = {
        tokenIdentifier:
          pawnedNFTInfoFromSC.token_info.token_identifier.toString(),
        nonce: pawnedNFTInfoFromSC.token_info.token_nonce.toNumber(),
        loanAmount: pawnedNFTInfoFromSC.loan_amount.toNumber(),
        pawnDate: pawnedNFTInfoFromSC.pawn_date.toNumber(),
        actualExpiryDate: pawnedNFTInfoFromSC.actual_expiry_date.toNumber(),
        extendedExpiryDate: pawnedNFTInfoFromSC.extended_expiry_date.toNumber(),
        loanDurationTicks: pawnedNFTInfoFromSC.loan_duration_ticks.toNumber(),
        pawnFee: pawnedNFTInfoFromSC.pawn_fee.toNumber(),
        boosterNfts: [],
        boostLevel: pawnedNFTInfoFromSC.boost_level.name.toString(),
        loanBoostAmount: pawnedNFTInfoFromSC.loan_boost_amount.toString(),
        feeDiscount: pawnedNFTInfoFromSC.fee_discount.toNumber(),
        holder: pawnedNFTInfoFromSC.holder.toString(),
        nftCounter: pawnedNFTInfoFromSC.nft_counter.toNumber(),
        currentState: '',
        nftCommonName: '',
        url: '',
        tags: []
      };

      pawnedNFTsLocal.push(pawnNFT);
    }

    await CommonHelper.loadNftData(pawnedNFTsLocal);
    return pawnedNFTsLocal;
  }

  static async loadNftData(pawnedNFTsLocal: PawnNFT[] | PawnHistory[]) {
    const fetchedNFTs = [];
    let allNFTsCommaSaperated = '';
    let totalNFTsToProcess = pawnedNFTsLocal.length;

    let nftIndex = 0;

    while (totalNFTsToProcess > 0) {
      let upperBound = totalNFTsToProcess;
      if (totalNFTsToProcess > 25) {
        upperBound = 25;
        totalNFTsToProcess -= 25;
      } else {
        totalNFTsToProcess = 0;
      }
      for (let index = 0; index < upperBound; index++) {
        const nft = pawnedNFTsLocal[nftIndex];
        nftIndex++;
        let nonce = `${nft.nonce.toString(16)}`;
        if (nonce.length % 2 !== 0) {
          nonce = `0${nonce}`;
        }

        allNFTsCommaSaperated += `${nft.tokenIdentifier.toString()}-${nonce},`;
      }

      allNFTsCommaSaperated = allNFTsCommaSaperated.substring(
        0,
        allNFTsCommaSaperated.length - 1
      );

      const nftsUrl = `${baseURL}nfts?identifiers=${allNFTsCommaSaperated}`;
      const nftsInfo = await axios.get(nftsUrl);
      if (nftsInfo == null || undefined) {
        throw Error('nfts info could not found');
      }

      fetchedNFTs.push(...nftsInfo.data);
      allNFTsCommaSaperated = '';
    }

    for (let i = 0; i < fetchedNFTs.length; i++) {
      const nftInfo = fetchedNFTs[i];
      for (
        let pawnedNFTIndex = 0;
        pawnedNFTIndex < pawnedNFTsLocal.length;
        pawnedNFTIndex++
      ) {
        const pawnedObject = pawnedNFTsLocal[pawnedNFTIndex];
        if (
          nftInfo.collection === pawnedObject.tokenIdentifier &&
          nftInfo.nonce === pawnedObject.nonce
        ) {
          const nftType = nftInfo.url.split('.').pop();
          const imageurl =
            nftType.toLowerCase() != 'gif' && nftType.toLowerCase() != 'mp4'
              ? nftInfo.media[0].thumbnailUrl
              : nftInfo.url;

          pawnedObject.url = imageurl;

          pawnedObject.nftCommonName = nftInfo.name;
          if (nftInfo.attributes) {
            const attributes = atob(nftInfo.attributes);
            const tagStartIndex = attributes.indexOf('tags:');
            if (tagStartIndex > 0) {
              const lastSemicolonIndex = attributes.indexOf(';', tagStartIndex);
              const tagsData = attributes.substring(
                tagStartIndex,
                lastSemicolonIndex
              );
              const tagsRawData = tagsData.split(':').pop();
              if (tagsRawData) {
                const tagsArray = tagsRawData.split(',');
                pawnedObject.tags = tagsArray;
              }
            }
          } else {
            pawnedObject.tags = [];
          }
        }
      }
    }

    return pawnedNFTsLocal;
  }

  public static CreateReadableMsg(nftMessage: NFTmessage) {
    if (nftMessage.type.toUpperCase() === 'ERROR') {
      const msg = nftMessage.msg;
      const splittedMsg = msg.split(':');
      const errorCode = splittedMsg[0];
      switch (errorCode.toUpperCase()) {
        case 'ERR1000':
          {
            const errValues = splittedMsg[1].split(',');
            const requiredLoanAmount = CommonHelper.fixedNum(
              parseInt(errValues[0]) / 10 ** 18,
              4
            );
            const totalAllowedLoanValue = CommonHelper.fixedNum(
              parseInt(errValues[1]) / 10 ** 18,
              4
            );
            const newMsg = `Loan not approved, Required loan amount '${requiredLoanAmount} EGLD' is exceeding the total allowed loan amount '${totalAllowedLoanValue} EGLD'`;
            nftMessage.msg = newMsg;
          }
          break;
        case 'ERR1001':
          {
            const errValues = splittedMsg[1].split(',');
            const requiredLoanAmount = CommonHelper.fixedNum(
              parseInt(errValues[0]) / 10 ** 18,
              4
            );
            const maxAllowedLoanValue = CommonHelper.fixedNum(
              parseInt(errValues[1]) / 10 ** 18,
              4
            );
            const newMsg = `Loan not approved, Required loan amount '${requiredLoanAmount} EGLD' is exceeding the max allowed limit.'${maxAllowedLoanValue} EGLD'`;
            nftMessage.msg = newMsg;
          }
          break;
        case 'ERR1002':
          {
            const errValues = splittedMsg[1].split(',');
            const requiredLoanAmount = CommonHelper.fixedNum(
              parseInt(errValues[0]) / 10 ** 18,
              4
            );
            const allowedPoolLimit = parseInt(errValues[1]) / 10 ** 18;
            const currentBorrowedAmount = parseInt(errValues[2]) / 10 ** 18;
            const maxAllowedPoolLimit =
              allowedPoolLimit - currentBorrowedAmount;
            const newMsg = `Loan Failed, Required loan amount '${requiredLoanAmount} EGLD' is exceeding allowed loan pool limit. Allowed loan value at this moment is '${
              maxAllowedPoolLimit > 0
                ? CommonHelper.fixedNum(maxAllowedPoolLimit, 4)
                : 0
            } EGLD'`;
            nftMessage.msg = newMsg;
          }
          break;
        case 'ERR1003':
          {
            const errValues = splittedMsg[1].split(',');
            const requiredLoanAmount = CommonHelper.fixedNum(
              parseInt(errValues[0]) / 10 ** 18,
              4
            );
            const allowedLimitForOrg = parseInt(errValues[1]) / 10 ** 18;

            const currentBorrowedAmountByComp =
              parseInt(errValues[1]) / 10 ** 18;
            const maxAllowedLimitForOrg =
              allowedLimitForOrg - currentBorrowedAmountByComp;
            const newMsg = `Loan Failed, The loan amount of '${requiredLoanAmount}' EGLD is more than the collection’s remaining lending pool allocation.  There is '${
              maxAllowedLimitForOrg > 0
                ? CommonHelper.fixedNum(maxAllowedLimitForOrg, 4)
                : 0
            }' EGLD remaining in the collection’s allocation.`;
            nftMessage.msg = newMsg;
          }
          break;
        case 'ERR1004':
          {
            const errValues = splittedMsg[1].split(',');
            const expiryDate = this.getDate(
              (parseInt(errValues[0]) * 1000).toString()
            );
            const currentDateTime = this.getDate(
              (parseInt(errValues[1]) * 1000).toString()
            );
            const newMsg = `Cannot claim the NFT as expiry date has been passed, nft expiry date/time: '${expiryDate}', current date/time '${currentDateTime}'`;
            nftMessage.msg = newMsg;
          }
          break;
        case 'ERR1005':
          {
            const errValues = splittedMsg[1].split(',');
            const sentAmount = CommonHelper.fixedNum(
              parseInt(errValues[0]) / 10 ** 18,
              6
            );
            const requiredAmount = CommonHelper.fixedNum(
              parseInt(errValues[1]) / 10 ** 18,
              6
            );
            const newMsg = `Cannot claim the NFT as Amount '${sentAmount}' sent with transaction is not exactly the required amount '${requiredAmount}'`;
            nftMessage.msg = newMsg;
          }
          break;
      }
    }

    return nftMessage;
  }

  public static getDate = (dateString: string) => {
    const date = new Date(parseInt(dateString));
    const month = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec'
    ];
    return (
      (date.getDate().toString().length % 2
        ? '0' + date.getDate()
        : date.getDate()) +
      '-' +
      month[date.getMonth()] +
      // ((date.getMonth() + 1).toString().length % 2
      //   ? '0' + (date.getMonth() + 1)
      //   : date.getMonth() + 1)
      '-' +
      date.getFullYear() +
      ' ' +
      (date.getHours().toString().length % 2
        ? '0' + date.getHours()
        : date.getHours()) +
      ':' +
      (date.getMinutes().toString().length % 2
        ? '0' + date.getMinutes()
        : date.getMinutes()) +
      ':' +
      (date.getSeconds().toString().length % 2
        ? '0' + date.getSeconds()
        : date.getSeconds())
    );
  };

  static async getAllTiersLoanInfo(proxy: ProxyNetworkProvider) {
    const tiersInfoFromSC = await PawnShopSmartContract.getAllTiersLoanInfo(
      proxy
    );
    const tiers: TierLoanInfo[] = [];
    for (let index = 0; index < tiersInfoFromSC.length; index++) {
      const tierLoanInfo: TierLoanInfo = {
        collectionTier: tiersInfoFromSC[index].collection_tier.name.toString(),
        collectionMaxLoanRatePercentage:
          tiersInfoFromSC[index].collection_max_loan_rate_percentage.toNumber()
      };
      tiers.push(tierLoanInfo);
    }

    return tiers;
  }

  public static async getAllCompaniesInfo(proxy: ProxyNetworkProvider) {
    const companyInfo = await PawnShopSmartContract.getAllCompaniesInfo(proxy);

    const companiesInfo: NFTCompany[] = [];
    companyInfo.forEach((element: any) => {
      const company: NFTCompany = {
        companyName: element.company_name.toString(),
        maxLoanPoolLimitPercentageForCompany:
          element.max_loan_pool_limit_percentage_for_company.toNumber(),
        loansAllowedForCompany: element.loans_allowed_for_company
      };
      companiesInfo.push(company);
    });

    return companiesInfo;
  }
  public static async getAllCollectionsInfo(proxy: ProxyNetworkProvider) {
    const collecitonsInfoFromSC =
      await PawnShopSmartContract.getAllNFTCollections(proxy);

    const collecitons: NFTCollectionAdmin[] = [];
    collecitonsInfoFromSC.forEach((element: any) => {
      const collecitonsData: NFTCollectionAdmin = {
        collectionName: element.collection_name.toString(),
        companyName: element.company_name.toString(),
        floorPrice: element.floor_price.toNumber() / 10 ** 18,
        collectionTokenIdentifier: element.collection_token_identifier,
        active: element.active,
        collectionTier: element.collection_tier.name
      };
      collecitons.push(collecitonsData);
    });
    return collecitons;
  }

  public static async getAllBoostersInfo(proxy: ProxyNetworkProvider) {
    const BoostersInfoFromSC = await PawnShopSmartContract.getAllBoostersInfo(
      proxy
    );

    const allBoostInfo: BoostInfo[] = [];
    BoostersInfoFromSC.forEach((element: any) => {
      const BoostInfoData: BoostInfo = {
        level: element.level.name,
        loanBoostPercentage: element.loan_boost_percentage.toNumber(),
        loanFeeDiscountPercentage:
          element.loan_fee_discount_percentage.toNumber()
      };
      allBoostInfo.push(BoostInfoData);
    });
    return allBoostInfo;
  }

  public static async getAllPawnFeeInfo(proxy: ProxyNetworkProvider) {
    const allPawnFeeInfoData = await PawnShopSmartContract.getAllPawnFeeInfo(
      proxy
    );

    const allPawnFeeInfo: PawnFeeInfoAdmin[] = [];
    allPawnFeeInfoData.forEach((element: any) => {
      const PawnFeeInfoData: PawnFeeInfoAdmin = {
        feePercentage: element.fee_percentage.toNumber(),
        loanDurationTicks: element.loan_duration_ticks.toNumber(),
        maxAllowedLoanExtensionTicks:
          element.max_allowed_loan_extension_ticks.toNumber()
      };
      allPawnFeeInfo.push(PawnFeeInfoData);
    });
    return allPawnFeeInfo;
  }

  public static async getAllLoanIssuedToCompanies(proxy: ProxyNetworkProvider) {
    const all_loan_data =
      await PawnShopSmartContract.getAllLoanIssuedToCompanies(proxy);

    const companyLoanIssueInfo: ComapnyLoanIssuedInfo[] = [];
    for (let index = 0; index < all_loan_data.valueOf().length; index++) {
      const company_loan_info_from_sc = all_loan_data.valueOf()[index];
      const loan_info: ComapnyLoanIssuedInfo = {
        companyName: company_loan_info_from_sc.company_name.toString(),
        loanIssued: company_loan_info_from_sc.loan_issued.toNumber()
      };
      companyLoanIssueInfo.push(loan_info);
    }

    return companyLoanIssueInfo;
  }

  public static getTierHexCode(tierName: string) {
    switch (tierName.toUpperCase()) {
      case 'DIAMOND':
        return '04';
      case 'PLATINUM':
        return '03';
      case 'GOLD':
        return '02';
      case 'SILVER':
        return '01';
      case 'BRONZE':
        return '00';
    }
  }

  public static getLevelHexCode(level: string) {
    switch (level.toUpperCase()) {
      case 'NOBOOST':
        return '00';
      case 'PAWN':
        return '01';
      case 'BISHOP':
        return '02';
      case 'KNIGHT':
        return '03';
      case 'ROOK':
        return '04';
      case 'QUEEN':
        return '05';
      case 'KING':
        return '06';
      case 'FULLSET':
        return '07';
    }
  }
  public static fixedNum(value: number, desimalLength: number) {
    if (isNaN(value)) {
      return;
    }
    const re = new RegExp('^-?\\d+(?:.\\d{0,' + (desimalLength || -1) + '})?');
    const strValue = value.toString();
    const valueArr: any[] = [];
    valueArr.push(strValue.match(re));
    if (valueArr.length) {
      return valueArr[0][0];
    }
  }
}

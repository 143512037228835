import { EnvironmentsEnum } from '@multiversx/sdk-dapp/types';

// //Devnet Info
// export const dAppName = 'PawnWhale';
// export const baseURL = 'https://devnet-api.multiversx.com/';
// export const stakingContractAddress =
//   'erd1qqqqqqqqqqqqqpgqmlczq9qhskcrjrnrkxuteurmn4egwnmzentsmqyayx';
// export const pawnShopContractAddress =
//   'erd1qqqqqqqqqqqqqpgqzy0x45pw3ya7rvd50h997ak0fu45hlmsents73c8sa';
// export const pawnShopOwnerAddress =
//   'erd13q8z4f5hxfwx0nd6xv8u8qq3fnf4y6w3crmqqe82v6y0aw9kentst8xy86';
// export const passCollectionIdentifier = 'PWDEV-e8fd4c';
// export const nodeAPIBaseURL = 'https://www.pawnwhale.com:50147';
// // export const nodeAPIBaseURL = 'http://localhost:3400';
// export const explorer_url = 'https://devnet-explorer.multiversx.com/';
// export const environment = EnvironmentsEnum.devnet;

// Mainnet Info
export const environment = EnvironmentsEnum.mainnet;
export const dAppName = 'PawnWhale';
export const baseURL = 'https://api.multiversx.com/';
export const stakingContractAddress =
  'erd1qqqqqqqqqqqqqpgqmsnswh9pw6gzwsshptkylxy0shd3ssws7vrs7r9ne9';
export const pawnShopContractAddress =
  'erd1qqqqqqqqqqqqqpgqf92y7l5guqyeym23uhaw8ms5r2zg0gym7vrs7074pq';
export const pawnShopOwnerAddress =
  'erd129sfmryjy2r5pdmryw2742rke9nctwpae7rjqnh3wcuaumle7vrsp8x2r3';
export const passCollectionIdentifier = 'PAWNWHALE-ef8360';
export const nodeAPIBaseURL = 'https://pawnwhale.com:50145';
//export const nodeAPIBaseURL = 'http://localhost:3300';
export const explorer_url = 'https://explorer.multiversx.com/';
// export const nodeAPIBaseURL = 'https://3.101.68.120:50145';

export const walletConnectV2ProjectId = '1fe680bd7a6d9c5a1d6a7a4965462004';
export const maxNftSelection = 20;
